<template>
  <section>
    <e-sidebar
      :title="sidebarTitle"
      :show="showSidebar"
      :fetching="fetching"
      width="900px"
      @hidden="hide"
    >
      <template #content>
        <b-card-actions
          no-actions
          :title="$t('Detalhes')"
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="sidebar_info_consumption-consumer_name"
                v-model="consumptionData.consumerName"
                :label="$t('Cliente')"
                type="label"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="sidebar_info_consumption-consumer_phone"
                v-model="consumptionData.consumerPhone"
                :label="$t('Telefone')"
                filter="phone"
                type="label"
              />
            </b-col>
            <b-col
              v-if="!isHistoryView"
              sm="4"
              md="2"
            >
              <p class="h6">
                {{ $t('Status comanda') }}
              </p>
              <e-status-badge
                :status="consumptionData.barTab.status"
                :options="barTabStatusOptions()"
              />
            </b-col>
            <b-col
              sm="4"
              md="2"
            >
              <p class="h6">
                {{ $t('Status Consumo') }}
              </p>
              <e-status-badge
                :status="consumptionData.status"
                :options="barTabConsumptionStatusOptions()"
              />
            </b-col>
            <b-col
              sm="4"
              md="2"
            >
              <p class="h6">
                {{ $t('Pagamento Confirmado') }}
              </p>
              <e-status-badge
                :status="consumptionData.paymentConfirmed"
                type="yesNo"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="sidebar_info_consumption-opening_date"
                v-model="consumptionData.createdDate"
                :label="$t('Data abertura')"
                filter="datetime"
                type="label"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="sidebar_info_consumption-sale_id"
                v-model="consumptionData.saleId"
                :label="$t('Venda')"
                type="label"
                :icons="saleIdLabelIcons"
                @link="showSale"
              />
            </b-col>

            <b-col md="4">
              <FormulateInput
                id="sidebar_info_consumption-user_request_closing"
                :value="getUserRequestClosing"
                :label="$t('Fechamento solicitado por')"
                type="label"
              />
            </b-col>

            <b-col md="4">
              <FormulateInput
                id="sidebar_info_consumption-user_confirmed_payment"
                :value="getUserConfirmedPayment"
                :label="$t('Pagamento confirmado por')"
                type="label"
              />
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions no-actions>
          <template #title>
            <div
              class="d-flex"
              style="gap: 10px"
            >
              <b-card-title>
                {{ $t('Consumos') }}
              </b-card-title>
            </div>
          </template>

          <b-row>
            <b-col>
              <FormulateInput
                id="sidebar_info_consumption-consumption_view_type"
                v-model="localForm.consumptionViewType"
                type="radio"
                :options="{
                  [consumptionViewTypeEnum.SUMMARY]: 'Resumido',
                  [consumptionViewTypeEnum.DETAILED]: 'Detalhado',
                }"
                :label="$t('Tipo de visualização')"
                :element-class="['d-flex', 'mt-1']"
              />
            </b-col>
          </b-row>

          <b-row>
            <!-- v-show está sendo usado para ter melhor performance na transição -->
            <b-col
              v-show="localForm.consumptionViewType == consumptionViewTypeEnum.SUMMARY"
              md="12"
            >
              <sale-items-table :sale="consumptionData.consumptionSummary" />
            </b-col>

            <b-col
              v-show="localForm.consumptionViewType == consumptionViewTypeEnum.DETAILED"
              md="12"
            >
              <partial-consumptions-table
                :partial-consumption-list="consumptionData.partialConsumptions"
                :fetching="fetching"
              />
            </b-col>

            <b-col md="12">
              <div class="line-total">
                <p class="h4">
                  {{ $t('Subtotal:') }}
                </p>
                <p class="h4">
                  {{ consumptionData.grossValue | currency(0, 2) }}
                </p>
              </div>

              <div
                v-if="hasWaiterComissionConfig || consumptionData.comission > 0"
                class="line-total"
              >
                <p class="h4">
                  {{ $t('Comissão') }}:
                </p>
                <p class="h4">
                  {{ consumptionData.comissionValue | currency(0, 2) }}
                </p>
              </div>

              <div class="line-total">
                <p class="h3">
                  {{ $t('Total:') }}
                </p>
                <p class="h3">
                  {{ consumptionData.netValue | currency(0, 2) }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="showSaleTotals"
          no-actions
          :title="$t('Venda faturada')"
        >
          <b-row>
            <b-col md="12">
              <div class="line-total">
                <p class="h4">
                  {{ $t('Subtotal:') }}
                </p>
                <p class="h4">
                  {{ getSaleData.grossValue | currency(0, 2) }}
                </p>
              </div>

              <div
                v-if="hasWaiterComissionConfig || getSaleData.comission > 0"
                class="line-total"
              >
                <p class="h4">
                  {{ $t('Comissão') }}:
                </p>
                <p class="h4">
                  {{ getSaleData.comission | currency(0, 2) }}
                </p>
              </div>

              <div class="line-total">
                <p class="h3">
                  {{ $t('Total:') }}
                </p>
                <p class="h3">
                  {{ getSaleData.netValue | currency(0, 2) }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="isHistoryView"
          no-actions
          :title="$t('Eventos do consumo')"
        >
          <bar-tab-consumption-events-table :items="getConsumptionEvents" />
        </b-card-actions>
      </template>

      <template #sidebar-footer>
        <e-button
          v-if="!(readOnly || isHistoryView) && hasWaiterComissionConfig"
          id="bar_consumption_sidebar-btn_change_comission"
          class="mr-1"
          variant="primary"
          icon="cup-fill"
          :text="$t('Alterar comissão')"
          :busy="finishing"
          @click="onShowComissionModal"
        />
        <e-button
          v-if="isElectron"
          id="bar_consumption_sidebar-btn_print_preview"
          class="mr-1"
          variant="primary"
          icon="printer-fill"
          :text="$t('Imprimir Conferência')"
          :busy="finishing"
          @click="onPrintConsumptionPreview"
        />
        <e-button
          v-if="!(readOnly || isHistoryView)"
          id="bar_consumption_sidebar-btn_finish"
          variant="primary"
          icon="check-circle"
          :text="$t('Fechar comanda')"
          :busy="finishing"
          @click="onFinishConsumption"
        />
      </template>
    </e-sidebar>
  </section>
</template>

<script>
import { BRow, BCol, BCardTitle } from 'bootstrap-vue'
import isElectron from 'is-electron'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import ESidebar from '@/views/components/ESidebar.vue'
import { mapActions, mapGetters } from 'vuex'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import { barDomain, loadingOverlay } from '@/mixins'
import SaleItemsTable from './components/SaleItemsTable.vue'
import PartialConsumptionsTable from './components/PartialConsumptionsTable.vue'
import BarTabConsumptionEventsTable from './components/BarTabConsumptionEventsTable.vue'

const consumptionViewTypeEnum = {
  SUMMARY: 'Summary',
  DETAILED: 'Detailed',
}

const getDefaultConsumptionInfo = () => ({
  id: null,
  saleId: null,
  sale: {
    netValue: 0,
    items: [],
  },
  consumptionSummary: {
    netValue: 0,
    items: [],
  },
  customer: null,
  barTab: {
    number: null,
  },
  status: null,
  barTabConsumptionEvents: [],
  partialConsumptions: [],
  consumerName: '',
  consumerPhone: '',
  observation: '',
  createdDate: null,
  comissionValue: 0,
  comissionFee: null,
})

const getDefaultLocalForm = () => ({
  consumptionViewType: consumptionViewTypeEnum.SUMMARY,
})

export default {
  name: 'BarTabConsumptionInfoSidebar',
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardActions,
    EButton,
    ESidebar,
    EStatusBadge,
    SaleItemsTable,
    PartialConsumptionsTable,
    BarTabConsumptionEventsTable,
  },

  mixins: [loadingOverlay, barDomain],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    isErp: {
      type: Boolean,
      default: false,
    },
    isHistoryView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      finishing: false,
      consumptionViewTypeEnum,
      localForm: getDefaultLocalForm(),
      consumptionData: getDefaultConsumptionInfo(),
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration', 'barConfig']),

    getConsumptionEvents() {
      return this.consumptionData?.barTabConsumptionEvents || []
    },
    getSaleData() {
      return this.consumptionData.sale || {}
    },

    getUserConfirmedPayment() {
      const { PAYMENT_CONFIRMED } = this.barTabConsumptionEventTypeEnum
      const paymentConfirmedEvent = this.getConsumptionEvents.find(
        ev => ev.eventType === PAYMENT_CONFIRMED
      )
      return paymentConfirmedEvent?.createdBy
        ? `${paymentConfirmedEvent?.createdBy}
            (${this.$options.filters.datetime(paymentConfirmedEvent?.createdDate)})`
        : null
    },

    getUserRequestClosing() {
      const { REQUEST_CLOSING } = this.barTabConsumptionEventTypeEnum
      const reqClosingEvent = this.getConsumptionEvents.find(
        ev => ev.eventType === REQUEST_CLOSING
      )
      return reqClosingEvent?.createdBy
        ? `${reqClosingEvent?.createdBy}
            (${this.$options.filters.datetime(reqClosingEvent?.createdDate)})`
        : null
    },

    sidebarTitle() {
      return `${this.$t('Dados da Comanda')} #${
        this.consumptionData.barTab.number || ''
      } (Consumo #${this.consumptionData.id || ''})`
    },

    hasWaiterComissionConfig() {
      return this.barConfig?.hasWaiterComission
    },

    isElectron() {
      return isElectron()
    },

    showSaleTotals() {
      const { CLOSED } = this.barTabConsumptionStatusEnum
      return this.isHistoryView && this.consumptionData.status === CLOSED
    },

    saleIdLabelIcons() {
      if (!this.isHistoryView || !this.consumptionData.saleId) return []
      return [
        {
          eventName: 'link',
          icon: 'box-arrow-up-right',
          clazz: '',
          variant: '',
          title: this.$t('Mostrar dados da venda'),
        },
      ]
    },
  },

  methods: {
    ...mapActions('pages/sale/sale', { stPrintOrder: 'printOrder' }),
    ...mapActions('pages/pdv/payBox', ['loadSaleInPayBox']),
    ...mapActions('pages/pdv/payBoxBar/tabs', [
      'fetchBarTabConsumptionById',
      'closeBarTabConsumption',
      'requestCloseBarTabConsumption',
      'updateComission',
    ]),

    async getData(consumptionId) {
      this.resetSidebarData()
      const defaultData = getDefaultConsumptionInfo()
      const data = await this.fetchBarTabConsumptionById(consumptionId)
      this.consumptionData = {
        ...data,
        sale: data.sale || defaultData.sale,
        consumptionSummary: data.consumptionSummary || defaultData.consumptionSummary,
        barTab: data.barTab || defaultData.barTab,
      }
    },

    hide() {
      this.resetSidebarData()
      this.finishing = false
      this.showSidebar = false
    },

    async show(consumptionId) {
      try {
        this.fetching = true
        this.showSidebar = true
        await this.getData(consumptionId)
      } catch (error) {
        this.showError({ error })
        this.hide()
      } finally {
        this.fetching = false
      }
    },

    resetSidebarData() {
      this.consumptionData = getDefaultConsumptionInfo()
      this.localForm = getDefaultLocalForm()
    },

    onShowComissionModal() {
      if (this.consumptionData.saleId) {
        this.showWarning({ message: this.$t('Comissão pode ser alterada apenas no caixa.') })
        return
      }

      this.$emit('show-modal-comission', { comissionFee: this.consumptionData.comissionFee })
    },
    async onConfirmComission(formData) {
      try {
        this.fetching = true
        await this.updateComission({
          tabNumber: this.consumptionData.barTab.number,
          formData: { ...formData, comissionFee: formData.comissionFee },
        })

        await this.getData(this.consumptionData.id)

        this.showSuccess({ message: this.$t('Comissão atualizado') })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async onFinishConsumption() {
      try {
        this.finishing = true

        const isToAskAboutComission =
          this.hasWaiterComissionConfig &&
          this.consumptionData?.grossValue > 0 &&
          !(this.consumptionData?.comissionFee > 0) &&
          !this.consumptionData.saleId
        if (isToAskAboutComission) {
          const confirmAddComission = await this.confirm({
            icon: 'question',
            title: this.$t('Deseja adicionar a comissão do garçom?'),
            text: 'Em caso afirmativo será necessário fechar a comanda novamente após selecionar a comissão.',
          })
          if (confirmAddComission) {
            this.onShowComissionModal()
            return
          }
        }

        const messageObject = {
          title: this.$t('Confirma o fechamento da comanda?'),
          text: this.$t(
            'Ao confirmar, o garçom não poderá adicionar mais produtos a esta comanda até ela ser finalizada no caixa.'
          ),
        }
        if (this.consumptionData.saleId) {
          messageObject.text = this.$t(
            'Esta comanda já teve o fechamento confirmado, esta ação apenas irá carregar esta comanda no caixa para a finalização'
          )
        }

        const confirmClosing = await this.confirm(messageObject)
        if (!confirmClosing) return

        this.showLoadingOverlay(this.$t('Iniciando fechamento da comanda'))

        const result = await this.closeBarTabConsumption({
          tabNumber: this.consumptionData.barTab.number,
          formData: { comissionFee: this.consumptionData.comissionFee },
        })
        if (result.status === this.barTabConsumptionStatusEnum.CLOSED) {
          this.showInformation({
            title: this.$t('Comanda Fechada'),
            text: this.$t('Comanda sem consumo fechada com sucesso.'),
            icon: 'success',
            confirmButtonText: this.$t('Ok'),
          })
          this.hide()
          this.$emit('reload-data')
          return
        }

        this.showLoadingOverlay(this.$t('Buscando dados para finalizar no caixa'))
        await this.loadSaleInPayBox({ saleId: result.saleId })

        this.$router.push({ name: 'pdv-pay-box' })
        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
        this.finishing = false
      }
    },

    async onPrintConsumptionPreview() {
      try {
        this.finishing = true
        await this.stPrintOrder({
          orderPrint: this.consumptionData?.consumptionSummary,
          template: 'SALE_BAR_TAB_CONSUMPTION',
        })

        const eventIds =
          this.consumptionData.barTabConsumptionEvents
            ?.filter(
              ev =>
                ev.hasBeenPrinted === false &&
                ev.eventType === this.barTabConsumptionEventTypeEnum.REQUEST_CLOSING
            )
            .map(ev => ev.id) ?? []

        if (eventIds.length > 0) {
          await this.$http.patch(
            `/api/bar-tab/consumption/store/${this.currentPayboxConfiguration?.store?.id}/request-closing/confirm-print`,
            { eventIds }
          )
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.finishing = false
      }
    },

    showSale() {
      if (this.isErp) {
        this.$router.push({ name: 'sale-read-only', params: { id: this.consumptionData.saleId } })
      } else {
        this.$emit('show-sale', this.consumptionData.saleId)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.line-total {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & + .line-total {
    padding-top: 5px;
    border-top: 1px solid var(--dark);
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        staticClass: "bordered mb-1",
        attrs: {
          striped: "",
          "show-empty": "",
          "empty-text": _vm.$t("Nenhum Evento registrado"),
          fields: _vm.itemFields,
          items: _vm.items,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <e-table-nested-rows
      :fields="tableField"
      :items="getItems"
      nested-list-property="items"
      :empty-text="$t('Nenhum consumo na comanda')"
      :fetching="fetching || isPrinting"
      init-colapsed
    >
      <template #detail-cell(quantity)="{ item }">
        <e-button
          v-if="isElectron"
          id="table-partial_consumption-btn_print"
          :title="$t('Imprimir consumo parcial')"
          icon="printer-fill"
          variant="primary"
          size="sm"
          style="margin-left: 5px"
          @click.stop="onReprintPartialConsumption(item)"
        />
      </template>

      <template #detail-cell(name)="{ item }">
        {{ `${$t('Data do consumo')}:` }} {{ item.createdDate | datetime }}
        <br>
        {{ `${$t('Atendente')}:` }} {{ item.createdBy || '-' }}
      </template>
      <template #nested-cell(name)="{ itemNested }">
        {{ itemNested.sku.name }}
      </template>

      <template #nested-cell(price)="{ itemNested }">
        <template v-if="itemNested.unitDiscount > 0">
          <span class="item-canceled-price">
            {{ itemNested.unitValue | currency }}
          </span>
          <br>
          <span> {{ itemNested.price | currency }} </span>
        </template>
        <template v-else>
          {{ itemNested.price | currency }}
        </template>
      </template>

      <template #detail-cell(netValue)="{ item }">
        {{ item.netValue | currency }}
      </template>
    </e-table-nested-rows>
  </div>
</template>

<script>
import _ from 'lodash'
import ETableNestedRows from '@/views/components/tables/ETableNestedRows.vue'
import { payBoxUtils } from '@/mixins'
import { mapActions } from 'vuex'
import EButton from '@/views/components/EButton.vue'
import isElectron from 'is-electron'

export default {
  components: { ETableNestedRows, EButton },

  props: {
    partialConsumptionList: {
      type: Array,
      required: true,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPrinting: false,
    }
  },

  computed: {
    getItems() {
      const dataFormated = this.partialConsumptionList.map(pConsumption => {
        const auxPCons = { ...pConsumption }
        if (auxPCons.items) {
          auxPCons.items = auxPCons.items.map(i => payBoxUtils.formatSimpleSaleItem(i))
        }
        return auxPCons
      })

      return _.sortBy(dataFormated, ['createdDate']).reverse()
    },

    isElectron() {
      return isElectron()
    },

    tableField() {
      return [
        {
          key: 'quantity',
          label: this.$t('QTD.'),
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          tdStyle: { width: '100px' },
        },
        {
          key: 'name',
          label: this.$t('Nome'),
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'price',
          label: this.$t('Preço'),
          thClass: 'text-center',
          tdClass: 'text-right',
          filter: 'currency',
          thStyle: { width: '120px' },
          tdStyle: { width: '120px' },
        },
        {
          key: 'netValue',
          label: this.$t('Total'),
          thClass: 'text-center',
          tdClass: 'text-right',
          filter: 'currency',
          thStyle: { width: '120px' },
          tdStyle: { width: '120px' },
        },
      ]
    },
  },

  methods: {
    ...mapActions('pages/pdv/payBoxBar', { stPrintPartialConsumption: 'printPartialConsumption' }),

    async onReprintPartialConsumption(item) {
      try {
        this.isPrinting = true
        const { data: partialConsumption } = await this.$http.get(
          `/api/bar-tab/partial-consumption/${item.id}`
        )
        await this.stPrintPartialConsumption({ partialConsumption })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.isPrinting = false
      }
    },
  },
}
</script>

<style>
.item-canceled-price {
  text-decoration: line-through;
  color: var(--danger);
}
</style>

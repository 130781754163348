<template>
  <div>
    <b-table
      class="bordered mb-1"
      striped
      show-empty
      :empty-text="$t('Nenhum produto consumido')"
      :fields="itemFields"
      :items="getItems"
    >
      <template #cell(price)="row">
        <template v-if="row.item.unitDiscount > 0">
          <span class="item-canceled-price">
            {{ row.item.unitValue | currency }}
          </span>
          <br>
          <span> {{ row.item.price | currency }} </span>
        </template>
        <template v-else>
          {{ row.item.price | currency }}
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { payBoxUtils } from '@/mixins'

export default {
  components: {
    BTable,
  },

  props: {
    sale: {
      type: Object,
      required: true,
    },
  },

  computed: {
    itemFields() {
      return [
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Preço'),
          key: 'price',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
        },
        {
          label: this.$t('Total'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
        },
      ]
    },
  },

  methods: {
    getItems() {
      if (!this.sale?.items) return []

      return this.sale.items.map(i => payBoxUtils.formatSimpleSaleItem(i))
    },
  },
}
</script>

<style>
.item-canceled-price {
  text-decoration: line-through;
  color: var(--danger);
}
</style>

<template>
  <div>
    <b-table
      class="bordered mb-1"
      striped
      show-empty
      :empty-text="$t('Nenhum Evento registrado')"
      :fields="itemFields"
      :items="items"
    />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { barDomain } from '@/mixins'

export default {
  components: {
    BTable,
  },
  mixins: [barDomain],

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    itemFields() {
      return [
        {
          label: this.$t('Evento'),
          key: 'eventType',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          formatter: value => this.barTabConsumptionEventTypeLabel[value] || value,
        },
        {
          label: this.$t('Data criação'),
          key: 'createdDate',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Criado por'),
          key: 'createdBy',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ]
    },
  },

  methods: {},
}
</script>

<style></style>

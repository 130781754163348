var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-table-nested-rows", {
        attrs: {
          fields: _vm.tableField,
          items: _vm.getItems,
          "nested-list-property": "items",
          "empty-text": _vm.$t("Nenhum consumo na comanda"),
          fetching: _vm.fetching || _vm.isPrinting,
          "init-colapsed": "",
        },
        scopedSlots: _vm._u([
          {
            key: "detail-cell(quantity)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm.isElectron
                  ? _c("e-button", {
                      staticStyle: { "margin-left": "5px" },
                      attrs: {
                        id: "table-partial_consumption-btn_print",
                        title: _vm.$t("Imprimir consumo parcial"),
                        icon: "printer-fill",
                        variant: "primary",
                        size: "sm",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onReprintPartialConsumption(item)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "detail-cell(name)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Data do consumo") + ":") +
                    " " +
                    _vm._s(_vm._f("datetime")(item.createdDate)) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Atendente") + ":") +
                    " " +
                    _vm._s(item.createdBy || "-") +
                    " "
                ),
              ]
            },
          },
          {
            key: "nested-cell(name)",
            fn: function (ref) {
              var itemNested = ref.itemNested
              return [_vm._v(" " + _vm._s(itemNested.sku.name) + " ")]
            },
          },
          {
            key: "nested-cell(price)",
            fn: function (ref) {
              var itemNested = ref.itemNested
              return [
                itemNested.unitDiscount > 0
                  ? [
                      _c("span", { staticClass: "item-canceled-price" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(itemNested.unitValue)) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(itemNested.price)) +
                            " "
                        ),
                      ]),
                    ]
                  : [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(itemNested.price)) + " "
                      ),
                    ],
              ]
            },
          },
          {
            key: "detail-cell(netValue)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(_vm._f("currency")(item.netValue)) + " "),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
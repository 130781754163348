var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.sidebarTitle,
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          width: "900px",
        },
        on: { hidden: _vm.hide },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              var _obj
              return [
                _c(
                  "b-card-actions",
                  { attrs: { "no-actions": "", title: _vm.$t("Detalhes") } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "3" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_consumption-consumer_name",
                                label: _vm.$t("Cliente"),
                                type: "label",
                              },
                              model: {
                                value: _vm.consumptionData.consumerName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.consumptionData,
                                    "consumerName",
                                    $$v
                                  )
                                },
                                expression: "consumptionData.consumerName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "3" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_consumption-consumer_phone",
                                label: _vm.$t("Telefone"),
                                filter: "phone",
                                type: "label",
                              },
                              model: {
                                value: _vm.consumptionData.consumerPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.consumptionData,
                                    "consumerPhone",
                                    $$v
                                  )
                                },
                                expression: "consumptionData.consumerPhone",
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.isHistoryView
                          ? _c(
                              "b-col",
                              { attrs: { sm: "4", md: "2" } },
                              [
                                _c("p", { staticClass: "h6" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Status comanda")) + " "
                                  ),
                                ]),
                                _c("e-status-badge", {
                                  attrs: {
                                    status: _vm.consumptionData.barTab.status,
                                    options: _vm.barTabStatusOptions(),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { attrs: { sm: "4", md: "2" } },
                          [
                            _c("p", { staticClass: "h6" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Status Consumo")) + " "
                              ),
                            ]),
                            _c("e-status-badge", {
                              attrs: {
                                status: _vm.consumptionData.status,
                                options: _vm.barTabConsumptionStatusOptions(),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "4", md: "2" } },
                          [
                            _c("p", { staticClass: "h6" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Pagamento Confirmado")) +
                                  " "
                              ),
                            ]),
                            _c("e-status-badge", {
                              attrs: {
                                status: _vm.consumptionData.paymentConfirmed,
                                type: "yesNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_consumption-opening_date",
                                label: _vm.$t("Data abertura"),
                                filter: "datetime",
                                type: "label",
                              },
                              model: {
                                value: _vm.consumptionData.createdDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.consumptionData,
                                    "createdDate",
                                    $$v
                                  )
                                },
                                expression: "consumptionData.createdDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_consumption-sale_id",
                                label: _vm.$t("Venda"),
                                type: "label",
                                icons: _vm.saleIdLabelIcons,
                              },
                              on: { link: _vm.showSale },
                              model: {
                                value: _vm.consumptionData.saleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.consumptionData, "saleId", $$v)
                                },
                                expression: "consumptionData.saleId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_consumption-user_request_closing",
                                value: _vm.getUserRequestClosing,
                                label: _vm.$t("Fechamento solicitado por"),
                                type: "label",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_consumption-user_confirmed_payment",
                                value: _vm.getUserConfirmedPayment,
                                label: _vm.$t("Pagamento confirmado por"),
                                type: "label",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card-actions",
                  {
                    attrs: { "no-actions": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex",
                                staticStyle: { gap: "10px" },
                              },
                              [
                                _c("b-card-title", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Consumos")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_consumption-consumption_view_type",
                                type: "radio",
                                options:
                                  ((_obj = {}),
                                  (_obj[_vm.consumptionViewTypeEnum.SUMMARY] =
                                    "Resumido"),
                                  (_obj[_vm.consumptionViewTypeEnum.DETAILED] =
                                    "Detalhado"),
                                  _obj),
                                label: _vm.$t("Tipo de visualização"),
                                "element-class": ["d-flex", "mt-1"],
                              },
                              model: {
                                value: _vm.localForm.consumptionViewType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.localForm,
                                    "consumptionViewType",
                                    $$v
                                  )
                                },
                                expression: "localForm.consumptionViewType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.localForm.consumptionViewType ==
                                  _vm.consumptionViewTypeEnum.SUMMARY,
                                expression:
                                  "localForm.consumptionViewType == consumptionViewTypeEnum.SUMMARY",
                              },
                            ],
                            attrs: { md: "12" },
                          },
                          [
                            _c("sale-items-table", {
                              attrs: {
                                sale: _vm.consumptionData.consumptionSummary,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.localForm.consumptionViewType ==
                                  _vm.consumptionViewTypeEnum.DETAILED,
                                expression:
                                  "localForm.consumptionViewType == consumptionViewTypeEnum.DETAILED",
                              },
                            ],
                            attrs: { md: "12" },
                          },
                          [
                            _c("partial-consumptions-table", {
                              attrs: {
                                "partial-consumption-list":
                                  _vm.consumptionData.partialConsumptions,
                                fetching: _vm.fetching,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("div", { staticClass: "line-total" }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Subtotal:")) + " "),
                            ]),
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.consumptionData.grossValue,
                                      0,
                                      2
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _vm.hasWaiterComissionConfig ||
                          _vm.consumptionData.comission > 0
                            ? _c("div", { staticClass: "line-total" }, [
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Comissão")) + ": "
                                  ),
                                ]),
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.consumptionData.comissionValue,
                                          0,
                                          2
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "line-total" }, [
                            _c("p", { staticClass: "h3" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Total:")) + " "),
                            ]),
                            _c("p", { staticClass: "h3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.consumptionData.netValue,
                                      0,
                                      2
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.showSaleTotals
                  ? _c(
                      "b-card-actions",
                      {
                        attrs: {
                          "no-actions": "",
                          title: _vm.$t("Venda faturada"),
                        },
                      },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { md: "12" } }, [
                              _c("div", { staticClass: "line-total" }, [
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Subtotal:")) + " "
                                  ),
                                ]),
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getSaleData.grossValue,
                                          0,
                                          2
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _vm.hasWaiterComissionConfig ||
                              _vm.getSaleData.comission > 0
                                ? _c("div", { staticClass: "line-total" }, [
                                    _c("p", { staticClass: "h4" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Comissão")) + ": "
                                      ),
                                    ]),
                                    _c("p", { staticClass: "h4" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.getSaleData.comission,
                                              0,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "line-total" }, [
                                _c("p", { staticClass: "h3" }, [
                                  _vm._v(" " + _vm._s(_vm.$t("Total:")) + " "),
                                ]),
                                _c("p", { staticClass: "h3" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getSaleData.netValue,
                                          0,
                                          2
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isHistoryView
                  ? _c(
                      "b-card-actions",
                      {
                        attrs: {
                          "no-actions": "",
                          title: _vm.$t("Eventos do consumo"),
                        },
                      },
                      [
                        _c("bar-tab-consumption-events-table", {
                          attrs: { items: _vm.getConsumptionEvents },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar-footer",
            fn: function () {
              return [
                !(_vm.readOnly || _vm.isHistoryView) &&
                _vm.hasWaiterComissionConfig
                  ? _c("e-button", {
                      staticClass: "mr-1",
                      attrs: {
                        id: "bar_consumption_sidebar-btn_change_comission",
                        variant: "primary",
                        icon: "cup-fill",
                        text: _vm.$t("Alterar comissão"),
                        busy: _vm.finishing,
                      },
                      on: { click: _vm.onShowComissionModal },
                    })
                  : _vm._e(),
                _vm.isElectron
                  ? _c("e-button", {
                      staticClass: "mr-1",
                      attrs: {
                        id: "bar_consumption_sidebar-btn_print_preview",
                        variant: "primary",
                        icon: "printer-fill",
                        text: _vm.$t("Imprimir Conferência"),
                        busy: _vm.finishing,
                      },
                      on: { click: _vm.onPrintConsumptionPreview },
                    })
                  : _vm._e(),
                !(_vm.readOnly || _vm.isHistoryView)
                  ? _c("e-button", {
                      attrs: {
                        id: "bar_consumption_sidebar-btn_finish",
                        variant: "primary",
                        icon: "check-circle",
                        text: _vm.$t("Fechar comanda"),
                        busy: _vm.finishing,
                      },
                      on: { click: _vm.onFinishConsumption },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
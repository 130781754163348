var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        staticClass: "bordered mb-1",
        attrs: {
          striped: "",
          "show-empty": "",
          "empty-text": _vm.$t("Nenhum produto consumido"),
          fields: _vm.itemFields,
          items: _vm.getItems,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(price)",
            fn: function (row) {
              return [
                row.item.unitDiscount > 0
                  ? [
                      _c("span", { staticClass: "item-canceled-price" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(row.item.unitValue)) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm._f("currency")(row.item.price)) + " "
                        ),
                      ]),
                    ]
                  : [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(row.item.price)) + " "
                      ),
                    ],
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }